import React from "react";

const ClientCard = ({ imgSrc, altText, clientName }) => (
  <div className="col-md-4 d-flex justify-content-center" style={{width:"220px"}}>
    <div className="client-card text-center" >
      <img src={imgSrc} alt={altText} className="client-img mb-2" />
      <h5>{clientName}</h5>
    </div>
  </div>
);

export default ClientCard;