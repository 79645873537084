import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShareAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import Confetti from "react-confetti";
import "../CSS/HackathonShowcase.css";

// Simple loader component
const Loader = () => (
  <div className="loader">
    <p>Loading...</p>
  </div>
);

const winners = [
  {
    rank: "🥇",
    title: "Intelligent API Testing Tool using Robot Framework",
    position: "( #1st )",
    desc: "Implement a utility that integrates performance testing into Robot Framework. This utility can simulate user load on APIs and measure response times, throughput, and error rates.",
    link: "hackathonppt/team18.pdf",
  },
  {
    rank: "🥈",
    title: "Reactive API Development",
    position: "( #2nd )",
    desc: "Demonstrate ability to develop reactive & non-blocking APIs with below features: Integration with other APIs in reactive fashion. Integration with database in reactive fashion. Load test these APIs to visualize performance difference",
    link: "hackathonppt/team13.pdf",
  },
  {
    rank: "🥉",
    title: "TestGenie: Your AI-Powered Coding and Testing Assistant",
    position: "( #3rd )",
    desc: "This utility  enhance your API contracts to use best practices. ●A VS Code extension that leverages AI to generate test cases from OpenAPI contracts (JSON/YAML).",
    link: "hackathonppt/team29.pdf",
  },
  {
    rank: "🏅",
    title: "Chat based code debugger",
    position: "( #4th )",
    desc: "Code Pilot is an intelligent debugging assistant that transforms the debugging experience through natural language interaction. It combines the power of AI with real-time code analysis to help developers understand and fix issues faster. The bot reads terminal output, provides detailed explanations of errors, and suggests solutions - all through a conversational interface",
    link: "hackathonppt/team30.pdf",
  },
];

const HackathonShowcase = ({ data }) => {
  const [ideas, setIdeas] = useState([]);
  const [sharedIdeas, setSharedIdeas] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      const filteredIdeas = data.data.filter((item) => item.path.startsWith("hackathonppt/"));
      setIdeas(filteredIdeas);
      setLoading(false); 
    } else {
      console.error("Data is not an array:", data);
      setLoading(false); 
    }
  }, [data]);

  const handleShare = (index) => {
    console.log("Share button clicked for index:", index);
    const idea = ideas[index];
    const shareData = {
      title: idea.title,
      text: idea.desc,
      url: window.location.href,
    };
    navigator.share(shareData).then(() => {
      setSharedIdeas([...sharedIdeas, index]);
    }).catch(console.error);
  };

  const handleView = (index) => {
    console.log("View button clicked for index:", index);
    const idea = ideas[index];
    console.log(idea, index, "idea index")
    const matchedSasUrl = data.sasUrls.find(
      (item) => item.blob_path === idea.path
    );
    if (matchedSasUrl) {
      console.log("Matched SAS URL:", matchedSasUrl.sas_url);
      navigate("/view-pdf", { state: { fileUrl: matchedSasUrl.sas_url } });
    } else {
      console.error("No matching SAS URL found for path:", idea.path);
    }
  };

  const handleViewWinners = (path) => {
    console.log("View button clicked for path:", path);
    const matchedSasUrl = data.sasUrls.find(
      (item) => item.blob_path === path
    );
    if (matchedSasUrl) {
      console.log("Matched SAS URL:", matchedSasUrl.sas_url);
      navigate("/view-pdf", { state: { fileUrl: matchedSasUrl.sas_url } });
    } else {
      console.error("No matching SAS URL found for path:", path);
    }
  };

  const handleSlideHover = () => {
    console.log("Slide hovered");
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="container">
      {showConfetti && <Confetti />}
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="header"
        style={{ marginTop: "150px" }}
      >
        🚀 Hackathon Ideas & Winners
      </motion.h1>

      <section className="winners-section">
        <h2>🏆 Top 4 </h2>
        <Slider {...sliderSettings}>
          {winners.map((winner, index) => (
            <motion.div
              key={index}
              className="winner-card"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              onMouseEnter={handleSlideHover}
            >
              <h3>
                {winner.rank} {winner.title} {winner.position}
              </h3>
              <p style={{fontSize:"15px"}}>{winner.desc}</p>
              <p onClick={(e) => { e.stopPropagation(); handleViewWinners(winner.link); }}>
                <FontAwesomeIcon icon={faEye} /> View more
              </p>
            </motion.div>
          ))}
        </Slider>
      </section>

      <section className="ideas-section">
        <h2 style={{ marginTop: "50px", color: "black" }}>💡 Selected Hackathon Ideas</h2>
        {loading ? (
          <Loader />
        ) : (
          <div className="ideas-grid">
            {ideas.map((idea, index) => (
              <motion.div
                key={index}
                className="idea-card"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3>{idea.title}</h3>
                <p>{idea.desc}</p>
                <p><strong>Team Members:</strong> {idea.team.join(", ")}</p>
                <div className="interaction-buttons">
                  <button onClick={() => handleShare(index)}>
                    <FontAwesomeIcon icon={faShareAlt} /> Share
                  </button>
                  <button onClick={() => handleView(index)}>
                    <FontAwesomeIcon icon={faEye} /> View more
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default HackathonShowcase;