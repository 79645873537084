import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "../CSS/MorePage.css";
import { cultureIcon, whitepaperIcon, blogsIcon, casestudiesIcon, rlus } from "../assets/images";
import chatIcon from "../assets/images/chaticon.png";
import ChatWindow from "./ChatWindow.js";

const MorePage = () => {
  const items = ["Culture", "Whitepaper", "Blogs", "casestudies", "client"];

  const [isChatOpen, setIsChatOpen] = useState(false);
  const itemIcons = {
    Culture: cultureIcon,
    Whitepaper: whitepaperIcon,
    Blogs: blogsIcon,
    casestudies: casestudiesIcon,
    client: rlus
  };
  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };

  // Sort items alphabetically in a case-insensitive manner
  const sortedItems = items.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  return (
    <div>
      <nav className="breadcrumb" style={{ marginBottom: "10px", marginTop: "82px" }}>
        <span><Link to="/node/more" style={{textDecoration:"none"}}>MORE</Link></span>
      </nav>
      <div className="card-container-nodepage">
        {sortedItems.map((item) => (
          <Link to={`/node/${item.toLowerCase()}`} className="card-link" key={item}>
            <div className="card-nodepage">
              <img
                src={itemIcons[item]}
                alt={item}
                width="30px"
                height="30px"
                style={{ height: "100px", width: "100px" }}
              />
              <div className="card-title-nodepage">{item}</div>
            </div>
          </Link>
        ))}
      </div>
      {/* Chat Icon Button */}
      <button className="chat-icon-button" onClick={toggleChatWindow}>
        <img src={chatIcon} alt="Chat" />
      </button>
      {/* Chat Window */}
      {isChatOpen && <ChatWindow onClose={toggleChatWindow} />}
      <Outlet />
    </div>
  );
};

export default MorePage;