import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../CSS/NodePage.css";
import "../CSS/Loader.css";
import Loader from "./Loader";
import Breadcrumb from "./Breadcrumb";
import chatIcon from "../assets/images/chaticon.png";
import ChatWindow from "./ChatWindow.js";

const EliteInnovators = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: metadata, sasUrls } = data;
  const [nodes, setNodes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [history, setHistory] = useState([]);

  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    if (metadata && metadata.length > 0) {
      const filteredDocuments = metadata.filter(
        (doc) => doc.path.startsWith("pod/") || doc.path.startsWith("awsappdev/Team Charter/Elite Innovators")
      );
      setNodes(filteredDocuments);
      setLoading(false);
      setHistory(location.state?.history || []);
    }
  }, [metadata, location.state]);

  const handleCardClick = (documentPath) => {
    const matchedSasUrl = sasUrls.find((item) => item.blob_path === documentPath);
    if (matchedSasUrl) {
      setSelectedFile(matchedSasUrl.sas_url);
    }
  };

  const handleCloseViewer = () => {
    setSelectedFile(null);
  };

  const handleBreadcrumbClick = (index) => {
    const newHistory = history.slice(0, index + 1);
    setHistory(newHistory);
    navigate(newHistory[newHistory.length - 1], { state: { data, history: newHistory } });
  };

  const shortenSummary = (summary) => {
    if (!summary) return "";
    const maxLength = 60;
    if (summary.length > maxLength) {
      return summary.substring(0, maxLength) + "...";
    }
    return summary;
  };

  return (
    <div>
      <nav className="breadcrumb" style={{ marginBottom: "10px", marginTop: "82px" }}>
        <span><Link to="/" style={{textDecoration:"none"}}>Home</Link></span>
      </nav>
      <Breadcrumb history={history} onBreadcrumbClick={handleBreadcrumbClick} />
      {loading ? (
        <Loader />
      ) : selectedFile ? (
        <div className="file-viewer" style={{marginTop:"-120px"}}>
          <button className="btn btn-danger mb-3" onClick={handleCloseViewer} style={{ marginLeft: "1000px" }}>
            Close Viewer
          </button>
          <embed
            src={selectedFile}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </div>
      ) : (
        <div className="card-container-nodepage" style={{marginTop:"-70px"}}>
          {nodes.map((node) => {
            const matchedSasUrl = sasUrls.find((item) => item.blob_path === node.iconimage);
            const imageUrl = matchedSasUrl ? matchedSasUrl.sas_url : null;

            return (
              <div
                key={node.path}
                onClick={() => handleCardClick(node.path)}
                className="card-nodepage"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    width="30px"
                    height="30px"
                    style={{ height: "80px", width: "80px" }}
                  />
                )}
                <div className="card-title-nodepage">{node.name_of_document}</div>
                <div className="node-details">
                  <p style={{ fontStyle: "60%" }}>{shortenSummary(node.summary)}</p>
                  <p><strong>Author:</strong> {node.author_name}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* Chat Icon Button */}
      <button className="chat-icon-button" onClick={toggleChatWindow}>
        <img src={chatIcon} alt="Chat" />
      </button>
      {/* Chat Window */}
      {isChatOpen && <ChatWindow onClose={toggleChatWindow} />}
    </div>
  );
};

export default EliteInnovators;