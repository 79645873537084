import React from "react";
import "../CSS/Loader.css"; 
const Loader = () => (
  <div className="loader">
    <div className="spinner"></div>
    <div className="dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  </div>
);

export default Loader;