import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { thumb7, hackathonIcon } from "../assets/images";

const CulturePage = ({data}) => {
  const navigate = useNavigate();

  return (
    <div className="card-container-homepage">
      <nav
        className="breadcrumb"
        style={{ marginBottom: "10px", marginTop: "82px" }}
      >
        <span>
          <Link to="/node/more" style={{ textDecoration: "none" }}>
            MORE
          </Link>{" "}
          / <span>Culture</span>
        </span>
      </nav>
      <div style={{ display: "flex" }}>
        <div
          className="card-nodepage"
          style={{ marginLeft: "220px", marginTop: "40px", height: "270px" }}
        >
          <Link to="/hackathon" className="card-link">
            <img
              src={hackathonIcon}
              alt="Hackathon"
              width="30px"
              height="30px"
              style={{ height: "100px", width: "100px" }}
            />
            <div className="card-title-nodepage">Hackathon- 2025 FEB</div>
            <br/>
            <p style={{fontFamily:"monospace", fontSize:"10px"}}>Click to Participate in Hackathon</p>
          </Link>
        </div>   
      </div>
    </div>
  );
};

export default CulturePage;
