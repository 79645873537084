import React, { useState } from "react";
import "../CSS/ChatWindow.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const ChatWindow = ({ onClose }) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const handleSendMessage = async () => {
    if (message.trim()) {
      setChatHistory([...chatHistory, { sender: "user", text: message }]);
      try {
        await msalInstance.initialize();

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found. User needs to log in.");
        }

        const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        const token = response.accessToken;

        const apiResponse = await fetch("https://appdevfunctionapp.azurewebsites.net/api/ChatAPI?code=e7gC1LSOvqqY7Jj8zxck2CHHWaCDOyuemviLweVPnASbAzFuEkrTjQ==", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ message }),
        });

        const text = await apiResponse.text();
        setChatHistory((prevHistory) => [...prevHistory, { sender: "bot", text }]);
      } catch (error) {
        console.error("Error sending message:", error);
        setChatHistory((prevHistory) => [...prevHistory, { sender: "bot", text: "Sorry, something went wrong." }]);
      }
      setMessage("");
    }
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        <h4>Chat with Us</h4>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="chat-body">
        {chatHistory.map((chat, index) => (
          <div key={index} className={`chat-message ${chat.sender}`}>
            {chat.text}
          </div>
        ))}
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatWindow;