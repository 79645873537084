import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "../App.css";

const SearchResults = () => {
  const location = useLocation();
  const searchResults = location.state?.searchResults || [];
  const [selectedFile, setSelectedFile] = useState(null);
  const sasUrls = useSelector((state) => state.data.sasUrls);

  const isFile = (node) => [".pdf", ".pptx", ".docx", ".xlsx", ".doc"].some(ext => node.endsWith(ext));

  const handleCardClick = (doc) => {
    const matchedSasUrl = sasUrls?.find(item => item.blob_path === doc.path);
    if (matchedSasUrl) setSelectedFile(matchedSasUrl.sas_url);
  };

  const isBlog = (nodeData) => {
    return nodeData && nodeData.path.startsWith("blogs/");
  };

  const shortenSummary = (summary) => {
    if (!summary) return "";
    const maxLength = 60;
    if (summary.length > maxLength) {
      return summary.substring(0, maxLength) + "...";
    }
    return summary;
  };

  return (
    <div>
      <h2 style={{marginTop:'100px'}}>Search Results</h2>
      {selectedFile ? (
        <embed src={selectedFile} type="application/pdf" width="100%" height="600px" />
      ) : (
        searchResults.length === 0 ? <p>No results found</p> :
        <div className="card-container-nodepage">
          {searchResults.map((doc, index) => {
            const matchedSasUrl = sasUrls.find(item => item.blob_path === doc.iconimage);
            const imageUrl = matchedSasUrl ? matchedSasUrl.sas_url : null;

            return isBlog(doc) ? (
              <div
                key={index}
                onClick={() => handleCardClick(doc)}
                className="card-nodepage blog-card"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    className="blog-image"
                  />
                )}
                <div className="blog-content">
                  <div className="card-title-nodepage">{doc.name_of_document}</div>
                  <div className="node-details">
                    <p>{shortenSummary(doc.summary)}</p>
                    <p><strong>Author:</strong> {doc.author_name}</p>
                    <img src={doc.profileimage} alt="Author" />
                    <button className="read-more-button" onClick={() => handleCardClick(doc)}>Read More</button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                onClick={() => handleCardClick(doc)}
                className="card-nodepage"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    width="30px"
                    height="30px"
                    style={{ height: "80px", width: "80px" }}
                  />
                )}
                <div className="card-title-nodepage">{doc.name_of_document}</div>
                <div className="node-details">
                  <p>{shortenSummary(doc.summary)}</p>
                  {isFile(doc.path) && (
                    <>
                      <p><strong>Author:</strong> {doc.author_name}</p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchResults;