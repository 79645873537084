import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../CSS/NodePage.css";
import "../CSS/Loader.css";
import Loader from "./Loader";
import Breadcrumb from "./Breadcrumb";
import chatIcon from "../assets/images/chaticon.png";
import ChatWindow from "./ChatWindow.js";

const NodePage = ({ data }) => {
  const { parentPath } = useParams();
  const navigate = useNavigate();
  const { data: metadata, sasUrls } = data;
  const [nodes, setNodes] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const baseUrl = "https://appdevstor.blob.core.windows.net/";
  const [fullPath, setFullPath] = useState(baseUrl);
  const [loading, setLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    if (metadata && metadata.length > 0) {
      setNodes([]);
      const newFullPath = parentPath ? `${baseUrl}${parentPath.replace(/ /g, "%20")}` : baseUrl;
      setFullPath(newFullPath);
      const newHistory = parentPath ? parentPath.split("/") : [];
      if (["whitepaper", "blogs", "casestudies", "client"].includes(parentPath?.toLowerCase())) {
        newHistory.unshift("More");
      }
      setHistory(newHistory);
      if (parentPath) {
        handleCardClick(metadata, parentPath, true);
      }
      setLoading(false);
    }
  }, [metadata, parentPath]);

  const handleCardClick = (urls, filterString, isInitial = false) => {
    if (!urls) return;
    const clickedFile = urls.find(
      (item) => item.path && item.path.endsWith(filterString)
    );
    if (clickedFile && isFile(filterString)) {
      const matchedSasUrl = sasUrls.find(
        (item) => item.blob_path === clickedFile.path
      );
      if (matchedSasUrl) {
        setSelectedFile(matchedSasUrl.sas_url);
      }
      return;
    }

    const filteredUrls = urls.filter(
      (item) => item.path && item.path.includes(filterString)
    );
    const transformedUrls = filteredUrls.map((item) => {
      const index = item.path.indexOf(filterString) + filterString.length;
      return item.path.slice(index + 1);
    });
    const resultSet = new Set(
      transformedUrls.map((url) => url.split("/")[0]).filter(Boolean)
    );
    const folders = [];
    const files = [];
    resultSet.forEach((node) => {
      if (isFile(node)) {
        files.push(node);
      } else {
        folders.push(node);
      }
    });

    folders.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    files.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    const sortedNodes = [...folders, ...files];
    setNodes(sortedNodes);

    if (!isInitial) {
      setHistory((prevHistory) => {
        if (prevHistory[prevHistory.length - 1] !== filterString) {
          return [...prevHistory, filterString];
        }
        return prevHistory;
      });
      setFullPath((prevFullPath) => {
        const newPath = `${prevFullPath}/${filterString}`.replace(/ /g, "%20");
        return newPath;
      });
    }
    setSelectedFile(null);
  };

  const shortenSummary = (summary) => {
    if (!summary) return "";
    const maxLength = 60;
    if (summary.length > maxLength) {
      return summary.substring(0, maxLength) + "...";
    }
    return summary;
  };

  const handleNodeClick = (node) => {
    setLoading(true);
    handleCardClick(metadata, node);
    setLoading(false);
  };

  const handleBreadcrumbClick = (index) => {
    const newHistory = history.slice(0, index + 1);
    let newPath = newHistory.join("/");
    if (newPath.startsWith("More/")) {
      newPath = newPath.slice(5);
    }
    setHistory(newHistory);
    navigate(`/node/${newPath}`);
    handleCardClick(metadata, newPath, true);
  };

  const isFile = (node) => {
    const fileExtensions = [".pdf", ".pptx", ".docx", ".xlsx", ".doc"];
    return fileExtensions.some((extension) => node.endsWith(extension));
  };

  const isBlog = (nodeData) => {
    return nodeData && nodeData.path.startsWith("blogs/");
  };

  return (
    <div>
      <Breadcrumb history={history} onBreadcrumbClick={handleBreadcrumbClick} />
      {loading ? (
        <Loader />
      ) : selectedFile ? (
        <div className="file-viewer">
          <button className="btn btn-danger mb-3" onClick={() => setSelectedFile(null)} style={{ marginLeft: "1000px", marginTop:"-55px" }}>
            Close Viewer
          </button>
          <embed
            src={selectedFile}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </div>
      ) : (
        <div className="card-container-nodepage">
          {nodes.map((node) => {
            const nodeData = metadata.find((item) => item.path.includes(node));
            const matchedSasUrl = sasUrls.find(
              (item) => item.blob_path === nodeData?.iconimage
            );
            const imageUrl = matchedSasUrl ? matchedSasUrl.sas_url : null;

            return isBlog(nodeData) ? (
              <div
                key={node}
                onClick={() => handleNodeClick(node)}
                className="card-nodepage blog-card"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    className="blog-image"
                  />
                )}
                <div className="blog-content">
                  <div className="card-title-nodepage">{nodeData.name_of_document}</div>
                  <div className="node-details">
                    <p style={{ fontStyle: "60%" }}>{shortenSummary(nodeData.summary)}</p>
                    <p><strong>Author:</strong> {nodeData.author_name}</p>
                    <button className="read-more-button" onClick={() => handleNodeClick(node)}>Read More</button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={node}
                onClick={() => handleNodeClick(node)}
                className="card-nodepage"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Node"
                    width="30px"
                    height="30px"
                    style={{ height: "80px", width: "80px" }}
                  />
                )}
                <div className="card-title-nodepage">{nodeData.name_of_document}</div>
                {nodeData && (
                  <div className="node-details">
                    <p style={{ fontStyle: "60%" }}>{shortenSummary(nodeData.summary)}</p>
                    {isFile(node) && (
                      <>
                        <p><strong>Author:</strong> {nodeData.author_name}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {/* Chat Icon Button */}
      <button className="chat-icon-button" onClick={toggleChatWindow}>
        <img src={chatIcon} alt="Chat" />
      </button>
      {/* Chat Window */}
      {isChatOpen && <ChatWindow onClose={toggleChatWindow} />}
    </div>
  );
};

export default NodePage;