export const msalConfig = {
  auth: {
    clientId: "3a011aba-3a69-4e23-ace0-b17b32de39d2",
    authority:
      "https://login.microsoftonline.com/638456b8-8343-4e48-9ebe-4f5cf9a1997d",
  // redirectUri: "http://localhost:3000/"

  
    redirectUri: "https://docs.yashappdev.link/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
