import React, { useEffect, useState } from "react";
import "../CSS/HomePage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import groupimg from "../assets/images/Team.jpeg";
import teamImg1 from "../assets/images/Team 1.png";
import teamImg2 from "../assets/images/Team 2.jfif";
import JD from "../assets/images/Jd.png";
import rlus from "../assets/images/rlus.png";
import delaval from "../assets/images/delaval.png";
import ServiceCard from "./ServiceCard";
import ClientCard from "./ClientCard";
import servicesData from "../assets/data/services.json";
import clientsData from "../assets/data/clients.json";
import "../CSS/Loader.css";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import chatIcon from "../assets/images/chaticon.png"; 
import ChatWindow from "./ChatWindow.js";

const HomePage = ({ data }) => {
  const [services, setServices] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [sasUrls, setSasUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setServices(servicesData);
      setClients(clientsData);
      setSasUrls(data.sasUrls);
      setLoading(false);
    }, 2000); // Simulate data fetching delay
  }, [data]);

  const clientImages = {
    rlus,
    JD,
    delaval,
  };

  const filteredDocuments = Array.isArray(data.data)
    ? data.data.filter((doc) => doc.path.startsWith("awsappdev/Team Charter/"))
    : [];

  const handleServiceCardClick = (documentPath, title) => {
    if (title === "Elite Innovators") {
      navigate("/elite-innovators");
    } else {
      const matchedSasUrl = sasUrls.find((item) => item.blob_path === documentPath);
      if (matchedSasUrl) {
        setSelectedDocument(matchedSasUrl.sas_url);
      }
    }
  };

  const handleCloseViewer = () => {
    setSelectedDocument(null);
  };

  const shortenSummary = (summary) => {
    if (!summary) return "";
    const maxLength = 70;
    if (summary.length > maxLength) {
      return summary.substring(0, maxLength) + "...";
    }
    return summary;
  };

  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };
  const clientChunks = chunkArray(clients, 3);
  return (
    <div>
      {/* Image Section */}
      <div className="image-section">
        <Carousel className="image-section">
          <Carousel.Item className="image-section">
            <img src={groupimg} alt="AWS APPDEV" className="img-fluid" />
          </Carousel.Item>
          <Carousel.Item className="image-section">
            <img src={teamImg1} alt="Team 1" className="img-fluid" />
          </Carousel.Item>
          <Carousel.Item className="image-section">
            <img src={teamImg2} alt="Team 2" className="img-fluid" />
          </Carousel.Item>
        </Carousel>
        <header className="hero-section">
          <div className="hero-overlay">
            <h1>Welcome to AWS APPDEV</h1>
            <p>Empowering businesses with cutting-edge solutions</p>
            <a href="#services" className="btn btn-primary btn-lg">
              Explore Our Teams
            </a>
          </div>
        </header>
      </div>

      {/* Team Services Section */}
      <section id="services" className="container my-5 text-center">
        <h2 className="section-title mb-4">Our Teams</h2>
        <center>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              {filteredDocuments.map((doc, index) => (
                <ServiceCard
                  key={index}
                  iconClass="fas fa-file-alt"
                  title={doc.name_of_document}
                  description={shortenSummary(doc.summary)}
                  documentPath={doc.path}
                  onClick={() => handleServiceCardClick(doc.path, doc.name_of_document)}
                />
              ))}
            </div>
          )}
        </center>
      </section>

      {/* Document Viewer Section */}
      {selectedDocument && (
        <section className="container my-5 text-center">
          <h2 className="section-title mb-4">Document Viewer</h2>
          <button className="btn btn-danger mb-3" onClick={handleCloseViewer} style={{ marginLeft: "1000px" }}>
            Close Viewer
          </button>
          <div className="file-viewer">
            <embed
              src={selectedDocument}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        </section>
      )}

      {/* Clients Section */}
      <section id="clients" className="container my-5 text-center">
        <h2
          className="section-title mb-4"
          style={{
            fontFamily: "Calibri",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Our Clients
        </h2>
        <center>
          <Carousel interval={3000}>
            {clientChunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className="row">
                  {chunk.map((client, idx) => (
                    <div className="col-md-3" key={idx} style={{marginLeft: "70px" }}>
                      <ClientCard
                        imgSrc={clientImages[client.imgKey]}
                        altText={client.altText}
                        clientName={client.clientName}
                      />
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </center>
      </section>

       {/* Mission and Vision Section */}
    <section className="mission-vision-section">
        <center>
          <h2>Our Mission and Vision</h2>
          <p style={{ marginLeft: "120px", marginRight: "120px" }}>
            Build best practices & capability using AWS Services & open Source to
            create reusable code base. Upon maturity, acts as a Shared Service &
            Continuous innovation center (Aligned with Industry).
          </p>
        </center>
        <center>
          <h2>Charter:</h2>
        </center>
        <div style={{ marginLeft: "250px" }}>
          <ul>
            <li>
              <strong>Develop & Maintain Capability on AWS Services (Own)</strong>
              <ul>
                <li>Scoping limited to App Dev & Containerization</li>
              </ul>
            </li>
            <li>
              <strong>
                Develop & Maintain Capability on AWS Well Architecture Program &
                participate in review / design process (Own)
              </strong>
              <ul>
                <li>Scoping limited to Serverless Application lens</li>
                <li>Container build lens</li>
                <ul>
                  <li>Joint responsibility with DevOPS Team</li>
                </ul>
                <li>SaaS lens</li>
              </ul>
            </li>
            <li>
              <strong>Develop & Maintain Conceptual Architecture. (Own)</strong>
              <ul>
                <li>
                  Constantly validate applicability through abstraction of client
                  engagements.
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Develop & Maintain Reference Architecture. On specific Tech.
                stacks (Own)
              </strong>
              <ul>
                <li>Scoping limited to app dev stacks on the AWS cloud.</li>
              </ul>
            </li>
            <li>
              <strong>Mainframe Modernization</strong>
              <ul>
                <li>
                  Check feasibility and build POC on mainframe modernization
                  services offered by AWS.
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Support pre-sales and lead to closure for App Dev side (Enabler)
              </strong>
            </li>
            <li>
              <strong>Support AWS alliance (Enabler)</strong>
              <ul>
                <li>POC on AWS Services</li>
                <li>Industry Trend</li>
              </ul>
            </li>
            <li>
              <strong>
                Develop & Maintain “machinery” for building Capacity for Delivery
                (Enabler)
              </strong>
              <ul>
                <li>
                  Hiring Support, Coaching, Mentoring, Training, Delivery
                  Architects and technical teams
                </li>
              </ul>
            </li>
            <li>
              <strong>Publish white papers and blogs</strong>
            </li>
            <li>
              <strong>
                Create re-usable components, template, best-practices etc and
                document
              </strong>
            </li>
            <li>
              <strong>
                Future roadmap include capacity building limiting to owned
                deliveries.
              </strong>
            </li>
          </ul>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-4">
        <div className="container">
          <p>
            &copy; {new Date().getFullYear()} Yash AWS Competency. All Rights
            Reserved.
          </p>
          <p>
            Follow us on{" "}
            <a href="https://linkedin.com" className="text-white">
              LinkedIn
            </a>
            {" | "}
            <a href="https://twitter.com" className="text-white">
              Twitter
            </a>
          </p>
        </div>
      </footer>

      {/* Chat Icon Button */}
      <button className="chat-icon-button" onClick={toggleChatWindow}>
        <img src={chatIcon} alt="Chat" />
      </button>

      {/* Chat Window */}
      {isChatOpen && <ChatWindow onClose={toggleChatWindow} />}
    </div>
  );
};

export default HomePage;