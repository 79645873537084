import React from "react";
import { Link, useLocation } from "react-router-dom";

const PDFViewerPage = () => {
  const location = useLocation();
  const { fileUrl } = location.state || {};

  return (
    <div className="file-viewer">
      {fileUrl ? (
        <>
          <div style={{ marginTop: "90px" }}>
            <Link to="/node/more" style={{ textDecoration: "none", color:"black"}}>
              MORE
            </Link>{" "}
            /{" "}
            <span>
              <Link to="/hackathon" style={{ textDecoration: "none" , color:"black"}}>
                Culture
              </Link>
            </span>
          </div>
          <embed
            src={fileUrl}
            type="application/pdf"
            width="100%"
            height="600px"
            
          />
        </>
      ) : (
        <p>No file selected</p>
      )}
    </div>
  );
};

export default PDFViewerPage;