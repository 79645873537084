import React from "react";

const ServiceCard = ({ iconClass, title, description, documentPath, onClick }) => {
  const handleClick = () => {
    onClick(documentPath);
  };

  return (
    <div className="col-md-4" onClick={handleClick}>
      <div className="card mb-4 shadow-sm">
        <div className="card-body">
          <i className={iconClass}></i>
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;