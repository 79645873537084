import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import Navbar from './components/Navbar';
import NodePage from './components/NodePage';
import HomePage from './components/HomePage';
import MorePage from './components/MorePage';
import Hackathon from './components/Hackathon';
import CulturePage from './components/CulturePage';
import { msalConfig, loginRequest } from './authConfig';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { fetchDetails } from './actions/dataActions';
import SearchResults from './components/SearchResults';
import HackathonImages from './components/HackathonImages';
import HackathonShowcase from './components/HackathonShowcase';
import PDFViewerPage from './components/PDFViewerPage';
import EliteInnovators from './components/EliteInnovators';

const msalInstance = new PublicClientApplication(msalConfig);

const Loading = () => (
  <div>Loading...</div>
);

const AppContent = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isInitialized, setIsInitialized] = useState(false);
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  console.log(data, "app.js");
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        await instance.handleRedirectPromise();
        setIsInitialized(true);
      } catch (e) {
        console.error("Initialization failed", e);
      }
    };
    initializeMsal();
  }, [instance]);
  useEffect(() => {
    const acquireToken = async () => {
      if (isAuthenticated && accounts.length > 0 && !token) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          setToken(response.accessToken);
        } catch (e) {
          console.error("Token acquisition failed", e);
        }
      }
    };
    acquireToken();
  }, [isAuthenticated, accounts, instance, token]);
  useEffect(() => {
    if (token) {
      dispatch(fetchDetails(token));
    }
  }, [token, dispatch]);
  useEffect(() => {
    if (!isAuthenticated && isInitialized) {
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, isInitialized, instance]);
  if (!isInitialized) {
    return <Loading />;
  }
  return (
 <>
 <Navbar />
 <Routes>
 <Route path="/" element={<HomePage data={data} />} />
 <Route path="/node/more" element={<MorePage />} />
 <Route path="/node/:parentPath/*" element={<NodePage data={data} />} />
 <Route path="/hackathon" element={<Hackathon data={data} />} />
 <Route path="/search" element={<SearchResults />} />
 <Route path="/node/culture" element={<CulturePage data={data} />} />
 <Route path="/hackathon-images" element={<HackathonImages />} />
 <Route path="/view-pdf" element={<PDFViewerPage />} />
 <Route path="/elite-innovators" element={<EliteInnovators data={data}/>} />
 </Routes>
 </>
  );
 };

function App() {
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        setIsMsalInitialized(true);
      } catch (e) {
        console.error("MSAL initialization failed", e);
      }
    };
    initializeMsal();
  }, []);

  if (!isMsalInitialized) {
    return <Loading />;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Router>
          <AppContent />
        </Router>
      </Provider>
    </MsalProvider>
  );
}

export default App;