import React, { useState } from "react";
import { YashLogo } from "../assets/images";
import { Link, useNavigate } from "react-router-dom";

import "../CSS/Navbar.css"
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { fetchDetails } from "../actions/dataActions"; 
import { FaSearch } from "react-icons/fa"; // Importing search icon

const Navbar = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [searchQuery, setSearchQuery] = useState("");

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => console.error("Logout failed", e));
  };

  const getUsername = (email) => {
    return email.split("@")[0];
  };

  const handleLinkClick = () => {
    setSearchQuery("");
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === "") return;
    try {
      const action = fetchDetails(searchQuery);
      const result = await dispatch(action);
      if (result.payload && result.payload.searchResults) {
        navigate("/search", { state: { searchResults: result.payload.searchResults } });
      } else {
        console.error("Error: API response does not contain searchResults");
      }
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  return (
    <header>
      <nav className="navbar-main">
        <div className="main-container d-flex align-items-center">
          <img
            src={YashLogo}
            alt="Yash"
            width="70"
            className="navbar-brand1"
            style={{ marginTop: "11px", marginBottom: "10px" }}
          />
          <Link to="/" className="navbar-brand1" onClick={handleLinkClick}>
            HOME
          </Link>
          <Link to="/node/awsappdev" className="navbar-brand1" onClick={handleLinkClick}>
            AWSAppDev
          </Link>
          <Link to="/node/technologies" className="navbar-brand1" onClick={handleLinkClick}>
            Technologies
          </Link>
          <Link to="/node/more" className="navbar-brand1" onClick={handleLinkClick}>
            More
          </Link>
          <div style={{ marginLeft: "auto" }}></div>
          <div className="search-bar">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for a document"
              className="search-input"
            />
            <FaSearch className="search-icon" onClick={handleSearch} /> {/* Adding search icon */}
          </div>
          {accounts.length > 0 && (
            <>
              <span className="navbar-brand1">
                {getUsername(accounts[0]?.username)}
              </span>
              <button className="login-button" onClick={handleLogout}>
                Logout
              </button>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;