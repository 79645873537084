import React, { useState } from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import '../App.css';
import images from '../assets/data/images';

const HackathonImages = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const moveNext = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % images.length);
  };

  const movePrev = () => {
    setSelectedImageIndex((selectedImageIndex + images.length - 1) % images.length);
  };

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={image.id} className="gallery-item" onClick={() => openModal(index)} aria-label={`Open ${image.title}`}>
          <img src={image.url} alt={image.title} loading="auto" srcSet={`${image.url} 1x, ${image.url.replace('.jpg', '@2x.jpg')} 2x`} />
        </div>
      ))}

      {selectedImageIndex !== null && (
        <Lightbox
          mainSrc={images[selectedImageIndex].url}
          nextSrc={images[(selectedImageIndex + 1) % images.length].url}
          prevSrc={images[(selectedImageIndex + images.length - 1) % images.length].url}
          onCloseRequest={closeModal}
          onMovePrevRequest={movePrev}
          onMoveNextRequest={moveNext}
          imageTitle={images[selectedImageIndex].title}
          onImageLoad={() => window.dispatchEvent(new Event('resize'))}
          toolbarButtons={[
            <a href={images[selectedImageIndex].url} download>
              Download
            </a>
          ]}
        />
      )}
    </div>
  );
};

export default HackathonImages;