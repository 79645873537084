import image1 from "../images/Hackathon_1.JPG";
import image2 from "../images/Hackathon_2.JPG";
import image3 from "../images/Hackathon_3.JPG";
import image4 from "../images/Hackathon_4.JPG";
import image5 from "../images/Hackathon_5.JPG";
import image6 from "../images/Hackathon_6.JPG";
import image7 from "../images/Hackathon_7.JPG";
import image8 from "../images/Hackathon_8.JPG";
import image9 from "../images/Hackathon_9.JPG";
import image10 from "../images/Hackathon_10.JPG";
import image0 from "../images/Hackathon_0.JPG";
import image11 from "../images/Hackathon_11.png";
import image12 from "../images/Hackathon_12.JPG";
import image13 from "../images/Hackathon_13.png";

import image14 from "../images/Hackathon_14.JPG";
import image15 from "../images/Hackathon_15.JPG";
import image16 from "../images/Hackathon_16.JPG";
import image17 from "../images/Hackathon_17.JPG";
import image18 from "../images/Hackathon_18.JPG";
import image19 from "../images/Hackathon_19.JPG";
import image20 from "../images/Hackathon_20.JPG";
import image21 from "../images/Hackathon_21.JPG";
import image22 from "../images/Hackathon_22.JPG";
import image23 from "../images/Hackathon_23.JPG";
import image24 from "../images/Hackathon_24.JPG";
import image25 from "../images/Hackathon_25.JPG";
import image26 from "../images/Hackathon_26.JPG";
import image27 from "../images/Hackathon_27.JPG";
import image28 from "../images/Hackathon_28.JPG";
import image29 from "../images/Hackathon_29.JPG";
import image30 from "../images/Hackathon_30.JPG";
import image31 from "../images/Hackathon_31.JPG";
const images = [
  {
    id: 0,
    url: image0,
    title: "Image 0",
  },
  {
    id: 1,
    url: image1,
    title: "Image 1",
  },
  {
    id: 2,
    url: image2,
    title: "Image 2",
  },
  {
    id: 3,
    url: image3,
    title: "Image 3",
  },
  {
    id: 4,
    url: image4,
    title: "Image 4",
  },
  {
    id: 5,
    url: image5,
    title: "Image 5",
  },
  {
    id: 6,
    url: image6,
    title: "Image 6",
  },
  {
    id: 7,
    url: image7,
    title: "Image 7",
  },
  {
    id: 8,
    url: image8,
    title: "Image 8",
  },
  {
    id: 9,
    url: image9,
    title: "Image 9",
  },
  {
    id: 10,
    url: image10,
    title: "Image 10",
  },
  {
    id: 11,
    url: image11,
    title: "Image 11",
  },
  {
    id: 12,
    url: image12,
    title: "Image 12",
  },
  {
    id: 13,
    url: image13,
    title: "Image 13",
  },
  {
    id: 14,
    url: image14,
    title: "Image 14",
  },
  {
    id: 15,
    url: image15,
    title: "Image 15",
  },
  {
    id: 16,
    url: image16,
    title: "Image 16",
  },
  {
    id: 17,
    url: image17,
    title: "Image 17",
  },
  {
    id: 18,
    url: image18,
    title: "Image 18",
  },
  {
    id: 19,
    url: image19,
    title: "Image 19",
  },
  {
    id: 20,
    url: image20,
    title: "Image 20",
  },
  {
    id: 21,
    url: image21,
    title: "Image 21",
  },
  {
    id: 22,
    url: image22,
    title: "Image 22",
  },
  {
    id: 23,
    url: image23,
    title: "Image 23",
  },
  {
    id: 24,
    url: image24,
    title: "Image 24",
  },
  {
    id: 25,
    url: image25,
    title: "Image 25",
  },
  {
    id: 26,
    url: image26,
    title: "Image 26",
  },
  {
    id: 27,
    url: image27,
    title: "Image 27",
  },
  {
    id: 28,
    url: image28,
    title: "Image 28",
  },
  {
    id: 29,
    url: image29,
    title: "Image 29",
  },
  {
    id: 30,
    url: image30,
    title: "Image 30",
  },
  {
    id: 31,
    url: image31,
    title: "Image 31",
  },
];

export default images;
