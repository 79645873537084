import React from "react";
import "../CSS/BreadCrumb.css";

const Breadcrumb = ({ history, onBreadcrumbClick }) => {
  const formatCrumb = (crumb) => {
    return crumb.replace(/[_-]+/g, ' ');
  };

  return (
    <div className="breadcrumb">
      {history.map((crumb, index) => (
        <span key={index} onClick={() => onBreadcrumbClick(index)} className="breadcrumb-text">
          {formatCrumb(crumb)}
          {index < history.length - 1 && " > "}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;