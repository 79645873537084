import React, { useEffect, useRef, useState } from "react";

import "../CSS/Hackathon.css"
import hackathon from "../assets/images/hackk.jpg";
import robot from "../assets/images/robot.png";
import HackathonShowcase from "./HackathonShowcase";
import { Link } from "react-router-dom";
import HackathonImages from "./HackathonImages";

const Hackathon = ({ data }) => {
  const sectionsRef = useRef([]);
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div className="hackathon-container1">
      <nav className="navbar-hackathon" style={{ marginTop: "80px" }}>
        <ul className="navbar-links-hackathon">
          <Link
            to="/node/culture"
            style={{
              textDecoration: "none",
              fontSize: "10px",
              marginTop: "8px",
            }}
          >
            MORE / Culture
          </Link>{" "}
          <li>
            <Link
              onClick={() => setActiveSection("home")}
              style={{
                textDecoration: "none",
                fontSize: "13px",
                marginTop: "8px",
              }}
            >
              Hackathon Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setActiveSection("images")}
              style={{
                textDecoration: "none",
                fontSize: "13px",
                marginTop: "8px",
              }}
            >
              Image Gallery
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setActiveSection("showcase")}
              style={{
                textDecoration: "none",
                fontSize: "13px",
                marginTop: "8px",
              }}
            >
              Winners & Ideas
            </Link>
          </li>
          <li></li>
        </ul>
      </nav>

      <div className="stars"></div>
      <div className="stars2"></div>
      <div className="stars3"></div>

      {activeSection === "home" && (
        <>
        <div className="hackathon-container">
          <section className="hero-hackathon">
            <div className="hero-overlay-hackathon">
              <h2 className="animate-slide-in" style={{ marginTop: "170px" }}>
                YASH Hackathon on APIs!
              </h2>
              <p
                style={{
                  fontSize: "12px",
                  marginLeft: "250px",
                  marginRight: "250px",
                }}
              >
                In today’s fast-paced digital landscape, the ability to deliver
                solutions quickly and efficiently is crucial. This hackathon
                aims to empower participants to build reusable components,
                define best practices, and explore architectural approaches that
                enhance our API ecosystem. By fostering collaboration and
                innovation, we aim to develop approaches that enable us to ship
                faster to our customers.
              </p>
              <section className="registration-hackathon">
                <a
                  href="https://www.yash.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="register-btn animate-bounce"
                >
                  To Register and get to know more about please contact
                  Sudhanshu Tiwari
                  <p>Email: sudhanshu.tiwari@yash.com</p>
                </a>
              </section>
            </div>
          </section>

          <div className="image-section-hackathon">
            <img src={hackathon} alt="" className="img-fluid-hackathon" />
          </div>
          <section
            className="about-hackathon"
            ref={(el) => sectionsRef.current.push(el)}
          >
            <div className="about-left">
              <center>
                <h2 className="blink" style={{ marginLeft: "500px" , color:"white"}}>
                  Objectives
                </h2>
              </center>
            </div>
            <div
              className="about-right"
              style={{ marginTop: "80px", marginLeft: "-690px" }}
            >
              <div
                className="card animate-slide-in"
                style={{ background: "#2e2e2e", color: "white" }}
              >
                <fieldset>
                  <legend>Create Reusable Components</legend>
                  <div className="card-content">
                    <p style={{ fontSize: "15px" }}>
                      Develop API components/Samples/Accelerator that can be
                      easily integrated into various applications.
                    </p>
                  </div>
                </fieldset>
              </div>
              <div
                className="card animate-slide-in"
                style={{ background: "#2e2e2e", color: "white" }}
              >
                <fieldset>
                  <legend>Enhance Speed to Market</legend>
                  <div className="card-content">
                    <p style={{ fontSize: "15px" }}>
                      Explore strategies and best practices for accelerating the
                      development and deployment of API-driven solutions.
                    </p>
                  </div>
                </fieldset>
              </div>
              <div
                className="card animate-slide-in"
                style={{ background: "#2e2e2e", color: "white" }}
              >
                <fieldset>
                  <legend>Foster Collaboration</legend>
                  <div className="card-content">
                    <p style={{ fontSize: "15px" }}>
                      Encourage teamwork and knowledge sharing among
                      participants to drive creative problem-solving.
                    </p>
                  </div>
                </fieldset>
              </div>
            </div>
          </section>
          <section
            className="schedule"
            ref={(el) => sectionsRef.current.push(el)}
          >
            <h2 style={{color:"white"}}>2025 Schedule</h2>
            <div className="timeline">
              <div className="timeline-item left">
                <div className="timeline-item-content">
                  <h3>FRIDAY, JANUARY 17, 2025</h3>
                  <p>Team Finalization and Challenge Identification</p>
                </div>
              </div>
              <div className="timeline-item right">
                <div className="timeline-item-content">
                  <h3>FRIDAY, JANUARY 31, 2025</h3>
                  <p>4:00 PM IST | Hackathon Launch (Teams call)</p>
                </div>
              </div>
              <div className="timeline-item left">
                <div className="timeline-item-content">
                  <h3>SATURDAY, FEBRUARY 1, 2025</h3>
                  <p>
                    10:00 AM to 6:00 PM IST | Teams Work on Challenges
                    <br />
                    Location – YIT Indore Office + Virtual (Only two team
                    members can be virtual)
                  </p>
                </div>
              </div>
              <div className="timeline-item right">
                <div className="timeline-item-content">
                  <h3>SUNDAY, FEBRUARY 2, 2025</h3>
                  <p>
                    10:00 AM to 6:00 PM IST | Challenge Continues
                    <br />
                    6:00 PM IST | Final Submission Due
                    <br />
                    Location – YIT Indore Office + Virtual (Only two team
                    members can be virtual)
                  </p>
                </div>
              </div>
              <div className="timeline-item left">
                <div className="timeline-item-content">
                  <h3>MONDAY, FEBRUARY 3, 2025</h3>
                  <p>10:00 AM Deadline for code Submission to the Repository</p>
                </div>
              </div>
            </div>
          </section>
          <img src={robot} alt="Robot Left" className="robot-left" />
          <img src={robot} alt="Robot Right" className="robot-right" />
          </div>
          </>
        
      )}

      {activeSection === "images" && (
        <section className="image-gallery">
          <h2>Image Gallery</h2>
          <HackathonImages />
        </section>
      )}

      {activeSection === "showcase" && <HackathonShowcase data={data} />}

      <footer className="footer-hackathon">
        <p>&copy; 2025 YASH Hackathon. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Hackathon;
